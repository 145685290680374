import GroupingMixin from '@/mixins/GroupingMixin';
import { mapGetters } from 'vuex';

export default {
  mixins: [GroupingMixin],

  computed: {
    ...mapGetters({
      userFilters: 'userFilters',
    }),

    selectedFiltersShorthand() {
      const { euGrouping } = this.userFilters;
      if (euGrouping) {
        const { groupPaths, euEpCommittees } = this.search.queryDetails;
        const result = [];

        const addShorthandForGroupCode = (groupCode) => {
          const group = euGrouping.groups[groupCode];
          const localization = this.getGroupLocalization(group, { type: 'short' });
          const paths = groupPaths.filter((path) => path.startsWith(groupCode));
          const rootNodeSelected = (paths.length === 1 && paths[0] === groupCode);
          let length;
          if (rootNodeSelected) {
            const { subGroups } = euGrouping.groups[groupCode];
            if (subGroups) ({ length } = Object.keys(subGroups));
          } else {
            ({ length } = paths);
          }
          if (length !== 0) result.push(`${localization}${length ? ` (${length})` : ''}`);
        };

        addShorthandForGroupCode('EC');
        addShorthandForGroupCode('EP-PL');
        // EP-COM - Based on committees instead of the docTypes!
        const euEpComGroup = euGrouping.groups['EP-COM'];
        const euEpComLocalization = this.getGroupLocalization(euEpComGroup, { type: 'short' });
        if (euEpCommittees.length > 0) result.push(`${euEpComLocalization} (${euEpCommittees.length})`);
        addShorthandForGroupCode('EP-COM-OVERIG');

        return result.join(', ');
      }
      return undefined;
    },
  },
};
